function sortArticles(article) {
  return article.sort(function (a, b) {
    const dateA = new Date(a.publishDate)
    const dateB = new Date(b.publishDate)
    return dateB - dateA
  })
}

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map(item => [item[key], item])).values()]
}

export const useArticlesData = ({ articlesData }) => {
  let articles = [
    { title: 'No Title', publishDate: '', tileImage: null, tags: null },
    { title: 'No Title', publishDate: '', tileImage: null, tags: null },
    { title: 'No Title', publishDate: '', tileImage: null, tags: null },
  ]

  if (articlesData) {
    if (articlesData[0].title) {
      articles = articlesData
    } else if (articlesData[0].article) {
      articles = []
      articlesData.map(tag => {
        if (tag.article !== null)
          tag.article.map(article => articles.push(article))
        return null
      })

      articles = sortArticles(articles)
      articles = getUniqueListBy(articles, 'title')
      articles = articles.slice(0, 3)
    }
  }

  return articles
}
