import React from 'react'
import { BgImage } from 'gbimage-bridge'
import { getImage } from 'gatsby-plugin-image'
import { Link } from 'gatsby'
import slugify from 'slugify'

const ArticleTile = ({ articleData, showTags }) => {
  const {
    title: articleTitle,
    tileImage: articleImage,
    tags: articleTags,
    publishDate: articlePublishDate,
  } = articleData
  const image = getImage(articleImage)
  const slug = slugify(articleTitle, { lower: true })
  const imageStack = [
    `linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5))`,
    image,
  ]

  return (
    <article className='p-0 md:p-4 md:pb-2 relative duration-200 transform xl:hover:scale-125'>
      <Link to={`/articles/${slug}`}>
        {showTags && articleTags && (
          <div className='absolute top-2 md:top-6 -left-2 md:left-2 bg-primary shadow-sm z-20 rounded-sm px-2'>
            {articleTags.map((tag, index) => (
              <span className='text-secondary-dark text-sm lowercase font-semibold tracking-widest'>
                {index > 0 && ' - '}
                {tag.name}
              </span>
            ))}
          </div>
        )}
        <BgImage
          alt={articleTitle}
          image={imageStack}
          className='absolute top-0 left-0 w-full h-52 filter rounded-lg shadow-lg flex flex-col justify-center safari-image-fix'
          >
          <div className='flex justify-center p-8 overflow-hidden'>
            <div className='text-center'>
              <h4 className='font-spar text-white text-xl mt-4 p-0'>
                {articleTitle}
              </h4>
              <p className='text-white text-xs text-center m-0 p-0'>
                {articlePublishDate}
              </p>
            </div>
          </div>
        </BgImage>
      </Link>
    </article>
  )
}

ArticleTile.defaultProps = {
  title: 'default title',
  showTags: false,
}

export default ArticleTile
