import React from 'react'
import ArticleTile from '../elements/ArticleTile'
import { useArticlesData } from '../../custom-query-hooks/useArticlesData'
import { Zoom } from 'react-awesome-reveal'

/* assets */
import greyLeftBracket from '../../assets/images/grey-left-bracket.svg'
import greyRightBracket from '../../assets/images/grey-right-bracket.svg'

const ArticlesSection = ({ title, articlesData }) => {
  const articles = useArticlesData({articlesData})

  return (
    <section className='bg-yellow-50 mt-8'>
      <div className='container flex flex-col xl:flex-row align-middle bg-yellow-50 p-4'>
        <div className='flex-grow-0 lg:self-center'>
          <h3 className='w-full text-center lg:text-left lg:pl-4 xl:w-36 xl:whitespace-pre-wrap m-0'>
            {title} Articles
          </h3>
        </div>
        <img
          className='mx-4 hidden xl:flex flex-grow-0 flex-shrink-0'
          src={greyLeftBracket}
          alt='Open Bracket'
        />
        <div className='flex-grow self-center mt-4 md:mt-0'>
          <Zoom triggerOnce>
            <div className='grid grid-cols-1 md:grid-cols-3 md:grid-rows-1 gap-0.5'>
              {articles.map((article, index) => (
                <div className=''>
                  <ArticleTile
                    key={index}
                    articleData={article}
                    showTags={true}
                  />
                </div>
              ))}
            </div>
          </Zoom>
        </div>
        <div className='mx-4 hidden xl:flex flex-grow-0 flex-shrink-0'>
          <img
            className='object-fit-fill'
            src={greyRightBracket}
            alt='Close Bracket'
          />
        </div>
      </div>
    </section>
  )
}

ArticlesSection.defaultProps = {
  title: 'Related',
}

export default ArticlesSection
